// https://github.com/getsentry/sentry/blob/42eda359bf9b18a02ff5dccea9763d846cda780c/src/sentry/static/sentry/app/utils/theme.tsx
import { get } from "lodash-es";
import { ThemeProvider } from "styled-components";
import { store } from "common/store";
import { color } from "common/themes/designTokens/color.json";
import { wpDesignTokens } from "@pazien/component-library";

const isUseUiTheme = store?.getState?.()?.settings?.isUseUiTheme; // L1

const spaces = {
    "0": "0",
    "0.25": "2px",
    "0.5": "4px",
    "0.75": "6px",
    "1": "8px",
    "1.5": "12px",
    "2": "16px",
    "3": "20px",
    "4": "30px"
};

const fontSizes = {
    "0": "36px", // font-size: ${({ theme }) => theme.headerFontSizeLarge};
    "1": "27px", // font-size: ${({ theme }) => theme.headerFontSizeMedium};
    "2": "20px", // font-size: ${({ theme }) => theme.headerFontSize};
    "3": "16px", // font-size: ${({ theme }) => theme.fontSizeExtraLarge};
    "4": "14px", // font-size: ${({ theme }) => theme.fontSizeLarge};
    "5": "12px", // font-size: ${({ theme }) => theme.fontSizeMedium};
    "6": "10px", // font-size: ${({ theme }) => theme.fontSizeSmall};
    "7": "8px",
    get h0() { return this["0"]; },
    get h1() { return this["1"]; },
    get h2() { return this["2"]; },
    get h3() { return this["3"]; },
    get h4() { return this["4"]; },
    get h5() { return this["5"]; },
    get h6() { return this["6"]; },
    get h7() { return this["7"]; },
    get h8() { return this["8"]; }
};

const fontStyles = {
    regular: "\"nunito_sans_regular\", sans-serif", // font-family: ${({ theme }) => theme.text.regular};
    medium: "\"nunito_sans_semibold\", sans-serif", // font-family: ${({ theme }) => theme.text.medium};
    bold: "\"nunito_sans_bold\", sans-serif", // font-family: ${({ theme }) => theme.text.bold};
    light: "\"nunito_sans_light\", sans-serif" // font-family: ${({ theme }) => theme.text.light};
};

const {
    blue3,
    blue6,
    brick1,
    green1,
    green4,
    keylime1,
    orange3,
    purple3,
    wcag6
} = color;

const analyticsSectionColors = {
    authorizations: blue3.value,
    fees: orange3.value,
    settlements: wcag6.value,
    sales: wcag6.value,
    refunds: blue6.value,
    refundRate: blue6.value,
    netSettledCash: blue6.value,
    averageTicket: orange3.value,
    chargebacks: purple3.value,
    cashflow: green4.value,
    misc: keylime1.value,
    psd2_fraud: brick1.value,
    all_fraud: brick1.value,
    threedsflex: green1.value,
    fraudsight: green1.value
};

const ellipsis = (maxWidth = "100%", display = "inline-block") => `
    display: ${display};
    max-width: ${maxWidth};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ellipsis2 = (maxWidth = "100%") => `
    max-width: ${maxWidth};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

// From base.scss, to be main theme colors
const baseStyles = {
    // Text colors
    textColorPrimary: `${color.gray1.value} !default`, // this is primary text color
    textColorSecondary: `${color.gray4.value} !default`, // secondary text color where emphasize should be more on the primary
    textColorPrimaryKnockout: `${color.canvas4.value} !default`,

    // Background colors
    backgroundColorPrimary: `${color.canvas4.value} !default`, // grayish background on the page
    backgroundColorSecondary: `${color.canvas1.value} !default`, // one tone darker color for elts on the page

    // Branding colors
    colorCorpPrimary: `${color.wcag5.value} !default`,
    colorCorpSecondary: `${color.green4.value} !default`,
    
    // Misc colors
    colorActiveLink: `${color.green4.value} !default`,

    // Dimensions
    "page-padding": "16px !default",
    "popover-max-width": "500px !default",
    "sidebar-width": "180px !default",
    "sidebar-padding-large": "180px !default",
    "sidebar-small-width": "50px !default",
    "sidebar-padding-small": "50px !default",
    "sidebar-padding-small-plus-scroll": "70px !default",
    "sidebar-zindex": "1028 !default",

    "dimension-topbar-height": "50px !default",
    "dimension-topbar-border-width": "1px !default",

    "tab-bar-height": "39px !default",
    "top-content-bar-height": "62px !default"
};

const waTheme = {
    analyticsSectionColor: (section) => get(analyticsSectionColors, section),
    font: (style, size) => `font-family: ${fontStyles[style]};\nfont-size: ${fontSizes[size]};`, // Backwards compatibility with `@include font(style, size)` rule
    space: (size) => spaces[size],
    size: (size) => fontSizes[size], // Backwards compatibility with current typography system (h0..h7)
    
    fontSizeExtraSmall: "8px",
    fontSizeSmall: "10px",
    fontSizeMedium: "12px",
    fontSizeLarge: "14px",
    fontSizeExtraLarge: "16px",
    headerFontSize: "20px",
    headerFontSizeMedium: "27px",
    headerFontSizeLarge: "36px",
    text: { ...fontStyles },
    mixins: {
        ellipsis,
        ellipsis2
    },

    borderRadius: "2px",

    color,
    textColor: baseStyles.textColorPrimary,
    backgroundColor: baseStyles.backgroundColorPrimary,
    colorCorp: baseStyles.colorCorpPrimary,
    baseStyles
};

export const theme = isUseUiTheme
    ? {
        ...waTheme,
        color: wpDesignTokens.color
    }
    : waTheme;

/**
 * Gets the value or values of one or more design tokens.
 * @param {string} type color, font, etc
 * @param {string[]} names ["blue3"], ["white1", "black1"], etc
 * @todo We currently support only colors. Add support for other types
 *   when they become available.
 * @returns {{[key: string]: string}}
 */
export const getDesignTokens = (type, names) => {
    if (type !== "color") {
        throw new Error(`Unsupported design token type: ${type}`);
    }

    const result = names.reduce((acc, name) => {
        if (!theme.color[name]) {
            acc[name] = theme.color.white1;
            console.error(`PAZIEN ERROR: ${name} as a color is not currently supported. White is being used to keep the application from crashing.`);
        } else {
            acc[name] = theme.color[name].value;
        }
        return acc;
    }, {});

    return { ...result };
};

export const withTheme = (Component, selectedTheme = theme) => <ThemeProvider theme={selectedTheme}>{Component}</ThemeProvider>;

/*

NOTES

[L1]
TODO
The optional chaining we have here is primarily to ensure that Jest does
not fail because of the error "TypeError: _store.store.getState is not a
function". Once ui-theme is more fully implemented, we will need to use
a mocked store to test that values change when the `isUseUiTheme` toggle
is enabled.

*/
