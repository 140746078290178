import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const reducers = {
    FETCH_DATA_AVAILABILITY_SUCCESS: (state, action) => ({
        ...state,
        
        // There is a problem on the API now that it doesn't return ready-dates, meaning it is only useful to find if a day had an errored report
        // Store a reference in the state about this request so we don't go back to the API in SHOULD_FETCH_DATA_STATUS action
        requests: {
            ...state.requests,
            [`${action.payload.datetype}_${action.payload.dates.start}_${action.payload.dates.end}`]: 1
        },
        dates: {
            ...state.dates,
            [action.payload.datetype]: {
                ...state.dates[action.payload.datetype],
                ...action.payload.data
            }
        }
    }),
    FETCH_ALL_REPORTS_SUCCESS: (state, action) => ({
        ...state,
        all_reports: action.payload.data
    }),

    RESET_AVAILABILITY_STORE: (state, action) => ({
        ...initialState
    }),

    UPDATE_AVAILABILITY_STORE: (state, action) => ({
        ...state,
        ...action.payload
    }),

    SET_SDR_DATEPICKER: (state, action) => ({
        ...state,
        sdr: {
            ...state.sdr,
            date_range: {
                ...state.sdr.date_range,
                ...action.payload
            }
        }
    }),
    
    SET_SDR_QUALIFIERS: (state, action) => ({
        ...state,
        sdr: {
            ...state.sdr,
            qualifiers: action.payload
        }
    })
};

const initialState = {
    requests: {},
    all_reports: [],
    dates: {
        settlement: {},
        activity: {},
        report: {}
    },
    sdr: {
        view: "status", // either status or subscriptions
        qualifiers: "",
        date_range: {
            startDate: moment.utc().startOf("day").toISOString(),
            endDate: moment.utc().endOf("day").toISOString(),
            datetype: "report_date"
        }
    }
};

export const dataAvailabilitySlice = createSlice({
    name: "dataAvailability",
    initialState,
    reducers
});

export const dataAvailability = dataAvailabilitySlice.reducer;
export const actions = dataAvailabilitySlice.actions;
export const SET_SDR_DATEPICKER = actions.SET_SDR_DATEPICKER;
export const SET_SDR_QUALIFIERS = actions.SET_SDR_QUALIFIERS;
